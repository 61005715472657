const BACKEND_URLS = {
    PROD: "https://muzdalifa-backend.tsleem.com.sa/",
    THECODE: "https://muzdalifaback.thecode.sa/",
    DEV: "https://muzdalifa-backend-dev.tsleem.com.sa/",
    LOCAL_HOST: "http://127.0.0.1:8000/",
};

const FRONT_URLS = {
    PROD: "https://muzdalifa.tsleem.com.sa/",
    LOCAL_HOST: "http://localhost:8080/",
    THECODE: "https://muzdalifa.thecode.sa/",
    DEV: "https://muzdalifa-dev.tsleem.com.sa/",
};



export const CURRENT_URL_BACK = BACKEND_URLS.PROD
export const CURRENT_URL_FRONT = FRONT_URLS.PROD



const BACKEND_MENA_URLS = {
    TASLEEM: "https://backend.tsleem.com.sa/",
    THECODE: "https://tsleemback.thecode.sa/",
    DEV: "https://backend-dev.tsleem.com.sa/",
    LOCAL_HOST: "http://127.0.0.1:8000/",
};


const FRONT_MENA_URLS = {
    TASLEEM: "https://services.tsleem.com.sa/",
    LOCAL_HOST: "http://localhost:8080/",
    THECODE: "https://tsleem.thecode.sa/",
    DEV: "https://services-dev.tsleem.com.sa/",
    ARAFAT: "https://muzdalifa-dev.tsleem.com.sa/"
};

export const CURRENT_MENA_URL_BACK = BACKEND_MENA_URLS.TASLEEM
export const CURRENT_MENA_URL_FRONT = FRONT_MENA_URLS.TASLEEM
